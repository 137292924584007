import React from "react"

const SponsorTab = ({content, index}) => {
    const tabName = content.nameTab;
    const becomeASponsor = content.becomeASponsor;
    const logos = content.logosSponsor;


    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>

            {
                (becomeASponsor.backgroundImage || becomeASponsor.title || becomeASponsor.subtitle || becomeASponsor.button) && 
                <div className="join_us">
                    <div className="bg_image">
                        {
                            becomeASponsor.backgroundImage && 
                            <img src={becomeASponsor.backgroundImage.mediaItemUrl} alt={becomeASponsor.backgroundImage.altText} />
                        }
                    </div>
                    <div className="content_area">
                        { becomeASponsor.title && <h4>{becomeASponsor.title}</h4> }
                        { becomeASponsor.subtitle && <p>{becomeASponsor.subtitle}</p> }
                        {
                            becomeASponsor.button && 
                            <div className="btn_wrapper">
                                <a href={becomeASponsor.button.url} target={becomeASponsor.button.target}>{becomeASponsor.button.title}</a>
                            </div>
                        }                  
                    </div>
                </div>
            }

            {
                logos && 
                <div className="all_logo_images">
                    <div className="content_area">
                        <div className="img_wrapper">
                            {
                                logos.map((item, index) => {
                                    if(item && item.logo && item.logo.mediaItemUrl) {
                                        return (
                                            <div className="single_image">
                                                <img src={item.logo.mediaItemUrl} alt={item.logo.altText} />
                                            </div>
                                        )
                                    }
                                })
                            }

                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SponsorTab
