import React from "react"

const processTab = ({content, index}) => {
    const tabName = content.tabName;
    
    const goals = content.goals;
    const preferredProgram = content.preferredProgram;
    const titleWText = content.titleWText;
    const zoning = content.zoning;

    console.log(zoning);

    const goalsimages = goals.images;
    
    const preferredProgramprograms = preferredProgram.programs;
    const preferredProgramtext = preferredProgram.text;
    const preferredProgramtitle = preferredProgram.title;
    
    const titleWTextsubtitle = titleWText.subtitle;
    const titleWTexttext = titleWText.text;
    const titleWTexttitle = titleWText.title;




    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')


    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
            {
                (titleWTexttitle || titleWTextsubtitle || titleWTexttext) &&
                <div className="content-text-area">
                    {
                        titleWTexttitle &&  <h2 className="text-center">{titleWTexttitle}</h2>
                    }

                    {
                        titleWTextsubtitle && <h3 className="text-center">{titleWTextsubtitle}</h3>
                    }

                    { titleWTexttext && <div className="text-area" dangerouslySetInnerHTML={{ __html: titleWTexttext }}></div>}
                    
                </div>
            }

            {
                goalsimages &&

                <div className="goals-blue">
                    {
                        goalsimages.map((item, index) => {
                            if(item) {
                                return <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" />
                            }
                        })
                    }
                </div>
            }

            {
                (preferredProgramtitle || preferredProgramtext) &&
                <div className="content-text-area">
                    { preferredProgramtitle &&  <h3 className="text-center">{preferredProgramtitle}</h3>}
                    { preferredProgramtext && <div className="text-area" dangerouslySetInnerHTML={{ __html: preferredProgramtext }}></div>}
                </div>
            }


            {
                preferredProgramprograms &&
                <div className="program-blue">
                    {
                        preferredProgramprograms.map((item, index) => {
                            if(item) {
                                if(index == 2) {
                                    return <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" style={{width: '220px'}} />
                                } else {
                                    return  <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" />
                                }
                            }
                        })
                    }
                </div>
            }

            {
                zoning && zoning.map((item, index) => {
                    let zoningtitle = item.title;
                    let zoningtext = item.text;
                    let zoningimage = item.image;

                    let width = '1048px';

                    if(index == 1) {
                        width = '1036px';
                    }

                    if(index == 3) {
                        width = '908px';
                    }

                    if(index == 4) {
                        width = '1200px';
                    }

                    return (
                        <div>
                    {
                        (zoningtitle || zoningtext) && 
                        <div className="content-text-area">
                            { index == 3 && <div className="text-area" dangerouslySetInnerHTML={{ __html: '<br /><br />' }}></div> }
                            { zoningtitle && <h3 className="text-center">{zoningtitle}</h3> }
                            { zoningtext && <div className="text-area" dangerouslySetInnerHTML={{ __html: zoningtext }}></div>}
                        </div>
                    }

                    {
                        zoningimage && 
                        <div className="single-image-caption">
                            <figure className="figure">
                                <a href={zoningimage.mediaItemUrl} data-fancybox><img src={zoningimage.mediaItemUrl} alt={zoningimage.altText} className="figure-img img-fluid" style={{width: width}} /></a>
                            </figure>
                        </div>
                    }
                        </div>
                    )


                
                })
            }


        </div>
    )
}

export default processTab
