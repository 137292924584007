import React from "react"

const EngageWork = ({content}) => {
    const title = content.title;
    const backgroundImage = content.backgroundImage;
    const button = content.button;
    const items = content.items;

    if(title || backgroundImage || button || items) {
        return (
            <section className="engage-work">
            <div className="background-area">
                { backgroundImage && <img src={backgroundImage.mediaItemUrl} alt={backgroundImage.altText} className="img-fluid" /> }
                
            </div>
            <div className="content-area">
                { title && <h2 className="title-text">{title}</h2> }
                
    
                {
                    items &&
                    <div className="text-boxes">
                        {
                            items.map((item, index) => {
                                if(item) {
                                    return (
                                    <div className="text-box">
                                        { item.title && <h3>{item.title}</h3> }
                                        { item.text && <p dangerouslySetInnerHTML={{ __html: item.text }}></p> }
                                    </div>
                                    )
                                }
                            })
                        }
                    </div>
                }
    
                {
                    button && 
                    <a href={button.url} target={button.target} className="btn">{button.title}</a>
                }
    
            </div>
            </section>
        )
    } else {
        return(
            <div></div>
        )
    }

}

export default EngageWork
