import React from "react"

const designTab = ({content, index}) => {
    const tabName = content.tabName;

    const columnImages = content.columnImages;
    const imageWText = content.imageWText;
    const textWImage = content.textWImage;
    const threeColumnsText = content.threeColumnsText;
    
    const textWImagesubtitle = textWImage.subtitle;
    const textWImagetext = textWImage.text;
    const textWImagetext2 = textWImage.text2;
    const textWImagetext3 = textWImage.text3;
    const textWImagetitle = textWImage.title;
    const textWImagetitle2 = textWImage.title2;
    const textWImageimage = textWImage.image;
    
    const threeColumnsTexttext = threeColumnsText.text;
    const threeColumnsTexttitle = threeColumnsText.title;
    const threeColumnsTextcolumns = threeColumnsText.columns;




    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');


    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>

            {
                (textWImagetitle || textWImagesubtitle || textWImagetext) && 
                <div className="content-text-area">
                    { textWImagetitle && <h2 className="text-center">{textWImagetitle}</h2>}
                    { textWImagesubtitle && <h3 className="text-center">{textWImagesubtitle}</h3>}

                    { textWImagetext && <div className="text-area" dangerouslySetInnerHTML={{ __html: textWImagetext }}></div>}

                </div>
            }

            {
                textWImageimage && 
                <div className="single-image-caption">
                    <figure className="figure">
                        <a href={textWImageimage.mediaItemUrl} data-fancybox><img src={textWImageimage.mediaItemUrl} className="figure-img img-fluid" alt={textWImageimage.altText} style={{width: '866px'}} /></a>
                    </figure>
                </div>
            }


            {
                (textWImagetext2 || textWImagetitle2 || textWImagetext3) &&
                <div className="content-text-area">
                    { textWImagetext2 && <div className="text-area" dangerouslySetInnerHTML={{ __html: textWImagetext2 }}></div>}
                    <br />
                    <br />
                    { textWImagetitle2 && <h3 className="text-center">{ textWImagetitle2 }</h3> }
                    
                    { textWImagetext3 && <div className="text-area" dangerouslySetInnerHTML={{ __html: textWImagetext3 }}></div>}

                </div>
            }

            {
                columnImages && 
                <div className="smaller-column">
                    {
                        columnImages.map((item, index) => {
                            if(item) {
                                return (
                                    <div className={`column ${index < 2 ? 'half' : ''}`}>
                                        { item.title && <h3>{item.title}</h3>}
                                        { item.subtitle && <p dangerouslySetInnerHTML={{ __html: item.subtitle }}></p>}

                                        {
                                            item.image && 
                                            <a href={item.image.mediaItemUrl} data-fancybox><img src={item.image.mediaItemUrl} className="figure-img img-fluid" alt={item.image.altText} /></a>
                                        }
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            }

            {
                (threeColumnsTexttitle || threeColumnsTextcolumns) &&
                <div className="three-column-colored">
                    { threeColumnsTexttitle && <h3 className="text-center">{threeColumnsTexttitle}</h3> }

                    {
                        threeColumnsTextcolumns && 
                        <div className="column-boxes">
                            {
                                threeColumnsTextcolumns.map((item, index) => {
                                    if(item) {
                                        let bg_color = '#029AC1';

                                        if(index == 1) {
                                            bg_color = '#AAA93D';
                                        }

                                        if(index == 2) {
                                            bg_color = '#000000';
                                        }
                                        return (
                                            <div className="column-box" style={{backgroundColor: bg_color}}>
                                                { item.title && <p className="text-center"><strong>{item.title}</strong></p> }
                                                { item.text && <p dangerouslySetInnerHTML={{ __html: item.text }}></p>}
                                            </div>
                                        )
                                    }
                                })
                            }

                        </div>
                    }
                </div>
            }

            { threeColumnsTexttext && <div className="content-text-area" dangerouslySetInnerHTML={{ __html: threeColumnsTexttext }}></div>}

            {
                imageWText && imageWText.map((item, index) => {
                    if(item) {
                        let width = '844px';
                        return (
                            <div>
                                {
                                    item.image && 
                                    <div className="single-image-caption">
                                        <figure className="figure">
                                        <a href={item.image.mediaItemUrl} data-fancybox><img src={item.image.mediaItemUrl} className="figure-img img-fluid" alt={item.image.altText} style={{width: width}} /></a>
                                        </figure>
                                    </div>
                                }
        
                                { item.text && <div className="content-text-area" dangerouslySetInnerHTML={{ __html: item.text }}></div>}
                            </div>
                        )
                    }
                })
            }

        </div>
    )
}

export default designTab
