import React from "react"


const ArtYourGalleryTab = ({content, index}) => {
    const tabName = content.nameTab;
    const title = content.title;
    const images = content.images;


    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');
    if(images || title) {
        return (
            <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
                    <div className="gallery_wrapper">
                        <div className="content_wrapper">
                            { title && <h2 className="text-center">{title}</h2> }
                            
                            {
                                images && 
                                <div className="image_wrap">
                                    <div className="slider-image_ig">
                                        {
                                            images.map((item, index) => {
                                                if(item) {
                                                    return (
                                                        <div className="slider-image-box">
                                                            <a data-fancybox="gallery" href={item.image.mediaItemUrl}>
                                                                <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" />
                                                            </a>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
            </div>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default ArtYourGalleryTab
