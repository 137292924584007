import React, { useState } from "react"
import pinArt from '../../assets/images/svg/pin-art.svg';

const PastEventTab = ({content, index}) => {
    const [activeEvents, setActiveEvents] = useState('');

    const tabName = content.nameTab;
    const gallery = content.gallery;
    const grid = content.grid;
    const logos = content.logos;
    const titleWText = content.titleWText;

    function handleEvents(newValue) {
        setActiveEvents(newValue);
    }

    function hasEvents(value) {
        let hasAward = false;

        if(activeEvents == '') {
            hasAward = true;
        }

        if(value) {
            if(activeEvents.toLowerCase() == value.toLowerCase()) {
                hasAward = true;
            }
        }


        return hasAward;
    }


    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
            {
                (titleWText.title || titleWText.filters || titleWText.text) && 
                <div className="open_omaha_2021">
                    <div className="content_area">
                        <div className="text_area">
                            <div className="title_area">
                                { titleWText.title && <h2>{titleWText.title}</h2> }
                                
                                {
                                    titleWText.filters && 
                                    <div className="filter-box">
                                        <select className="form-select" onChange={e => handleEvents(e.target.value)}>
                                            <option selected="" value="">Events by years</option>
                                            {
                                                titleWText.filters.map((item, key) => {
                                                    if(item.year) {
                                                        return (
                                                            <option value={item.year}>{item.year}</option>
                                                        )
                                                    }
                                                })
                                            }
                                        </select>
                                    </div>
                                }
                            </div>
                            <div className="text_area">
                                { titleWText.text && <p>{titleWText.text}</p> }
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                (grid.title || grid.items) && 
                <div className="address_grid with_title">
                    {
                        grid.title && 
                        <div className="title_area">
                            <h3 className="text-capitalize">{grid.title}</h3>
                        </div>
                    }

                    {
                        grid.items && grid.items.map((item, index) => {
                            if(item) {
                                if(hasEvents(item.year)) {
                                    let show_link = false;
                                    if(item.link && item.text) {
                                        show_link = true;
                                    }
                                    return (
                                        <div className="item_grid">
                                            <div className="img_area">
                                                {
                                                    item.image &&
                                                    <img src={item.image.mediaItemUrl} alt={item.image.altText} />
                                                }
                                            </div>
                                            <div className="icon_area">
                                                <img src={pinArt} alt="svg pin" />
                                                {
                                                    item.text && show_link && <a href={item.link.url} target={item.link.target}><span>{item.text}</span> </a>
                                                }
                                                {
                                                    item.text && !show_link && <span>{item.text}</span> 
                                                }
                                                
                                            </div>
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                </div>
            }

            {
                (gallery.title || gallery.images) && 
                <div className="gallery_slider">
                    <div className="content_area">
                        <div className="title_area">
                            {
                                gallery.title && <h2>{gallery.title}</h2>
                            }
                            
                        </div>

                        {
                            gallery.images && 
                            <div className="slider-image">
                                {
                                    gallery.images.map((item, index) => {
                                        if(item) {
                                            return (
                                                <div className="slider-image-box">
                                                    <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" />
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            }

            {
                (logos.title || logos.images) && 
                <div className="all_logo_images with_title">
                    <div className="content_area">
                        <div className="title_area">
                            {
                                logos.title && <h2>{logos.title}</h2>
                            }
                            
                        </div>

                        {
                            logos.images && 
                            <div className="img_wrapper">
                                {
                                    logos.images.map((item, index) => {
                                        if(item) {
                                            return (
                                                <div className="single_image">
                                                    <img src={item.image.mediaItemUrl} alt={item.image.altText} />
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default PastEventTab
