import React from "react"

const partnersTab = ({content, index}) => {
    const tabName = content.tabName;
    const affordableByDesignTeam = content.affordableByDesignTeam;
    const textWImage = content.textWImage;
    const textWImage2 = content.textWImage2;
    const textWTabs = content.textWTabs;
    const textWThreeColumnsImage = content.textWThreeColumnsImage;
    const titleWImage = content.titleWImage;
    
    const affordableByDesignTeamsubtitle = affordableByDesignTeam.subtitle;
    const affordableByDesignTeamtitle = affordableByDesignTeam.title;
    const affordableByDesignTeamimages= affordableByDesignTeam.images;
    
    const textWImageimage = textWImage.image;
    const textWImageimageCaption= textWImage.imageCaption;
    const textWImagetext= textWImage.text;
    
    const textWImage2image = textWImage2.image;
    const textWImage2imageCaption= textWImage2.imageCaption;
    const textWImage2text= textWImage2.text;
    
    const textWTabstabs = textWTabs.tabs;
    const textWTabstext= textWTabs.text;
    
    const textWThreeColumnsImageimages = textWThreeColumnsImage.images;
    const textWThreeColumnsImagetext= textWThreeColumnsImage.text;
    const textWThreeColumnsImagetitle= textWThreeColumnsImage.title;
    
    const titleWImageimageCaption= titleWImage.imageCaption;
    let titleWImagetext = titleWImage.text;
    const titleWImagetitle = titleWImage.title;
    const titleWImageimage = titleWImage.image;

    if(titleWImagetext) {
        titleWImagetext = titleWImagetext.replace('<ul>', '<ul class="two-column">');
    }


    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')


    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>

            {
                affordableByDesignTeamtitle &&
                <div className="content-text-area">
                    <h2 className="text-center">{affordableByDesignTeamtitle}</h2>
                </div>
            }

            {
                (affordableByDesignTeamsubtitle || affordableByDesignTeamimages) &&
                <div className="affordable-design-team">
                    {
                        affordableByDesignTeamsubtitle && 
                        <h3 className="text-center">{affordableByDesignTeamsubtitle}</h3>
                    }

                    {
                        affordableByDesignTeamimages &&
                        <div className="blue-image-area">
                            {
                                affordableByDesignTeamimages.map((item, index) => {
                                    let width = '219px';
                                    if(index == 1) {
                                        width = '223px';
                                    }
                                    if(index == 2) {
                                        width = '276px';
                                    }
                                    if(index == 3) {
                                        width = '245px';
                                    }
                                    if(index == 4) {
                                        width = '213px';
                                    }
                                    if(item) {
                                        return <img src={item.image.mediaItemUrl} alt={item.image.altText}  className="img-fluid" style={{width: width}} />
                                    }
                                })
                            }                            

                        </div>
                    }
                </div>
            }

            {
                (textWThreeColumnsImagetitle || textWThreeColumnsImagetext) &&
                <div className="content-text-area">
                    {
                        textWThreeColumnsImagetitle &&
                        <h3 className="text-center">{textWThreeColumnsImagetitle}</h3>
                    }

                    { textWThreeColumnsImagetext && <div className="text-area" dangerouslySetInnerHTML={{ __html: textWThreeColumnsImagetext }}></div>}
                </div>
            }

            {
                textWThreeColumnsImageimages &&
                <div className="three-column-image-caption">
                    {
                        textWThreeColumnsImageimages.map((item, index) => {
                            if(item.image) {
                                return (
                                    <figure className="figure">
                                        <img src={item.image.mediaItemUrl} className="figure-img img-fluid" alt={item.image.mediaItemUrl} />
                                        {
                                            item.imageCaption && <figcaption className="figure-caption text-center">{item.imageCaption}</figcaption>
                                        }
                                        
                                    </figure>
                                )
                            }
                        })
                    }
                </div>
            }


            {
                textWImagetext && <div className="content-text-area" dangerouslySetInnerHTML={{ __html: textWImagetext }}></div>
            }

            {
                textWImageimage && 
                <div className="single-image-caption">
                    <figure className="figure">
                        <a href={textWImageimage.mediaItemUrl} data-fancybox><img src={textWImageimage.mediaItemUrl} className="figure-img img-fluid" alt={textWImageimage.altText} style={{width: '984px'}} /></a>
                        {
                            textWImageimageCaption && <figcaption className="figure-caption text-center">{textWImageimageCaption}</figcaption>
                        }
                    </figure>
                </div>
            }

            {
                textWImage2text && <div className="content-text-area" dangerouslySetInnerHTML={{ __html: textWImage2text }}></div>
            }

            {
                textWImage2image && 
                <div className="single-image-caption">
                    <figure className="figure">
                        <a href={textWImage2image.mediaItemUrl} data-fancybox><img src={textWImage2image.mediaItemUrl} className="figure-img img-fluid" alt={textWImage2image.altText} style={{width: '1044px'}} /></a>
                        {
                            textWImage2imageCaption && <figcaption className="figure-caption text-center">{textWImage2imageCaption}</figcaption>
                        }
                    </figure>
                </div>
            }

            {
                textWTabstext && <div className="content-text-area" dangerouslySetInnerHTML={{ __html: textWTabstext }}></div>
            }

            {
                textWTabstabs &&
                <div className="inner-tabs">
                    <nav className="nav_second">
                        <div className="slider_tab"></div>
                        <div className="nav nav-tabs" id="inner-nav-tab" role="tablist">
                            {
                                textWTabstabs.map((item, index) => {
                                    if(item.titleTab) {
                                        return <button className={`nav-link ${index == 0 ? 'active' : ''}`} id={`nav-${slugify(item.titleTab)}-tab`} data-bs-toggle="tab" data-bs-target={`#nav-${slugify(item.titleTab)}`} type="button" role="tab" aria-controls={`nav-${slugify(item.titleTab)}`} aria-selected="true">{item.titleTab}</button>
                                    }
                                })
                            }
                        
                        </div>
                    </nav>
                    <div className="tab-content" id="inner-nav-tabContent">
                        {
                            textWTabstabs.map((item, index) => {
                                if(item.titleTab) {
                                    return (
                                    <div className={`tab-pane fade ${index == 0 ? 'show active' : ''}`} id={`nav-${slugify(item.titleTab)}`} role="tabpanel" aria-labelledby={`nav-${slugify(item.titleTab)}-tab`} tabIndex="0">
                                        <div className="inner-tab-content">

                                        {
                                            item.text && <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                                        }

                                        {
                                            item.images &&
                                            <div className="slider-image">
                                                {
                                                    item.images.map((image, key_index) => {
                                                        if(image) {
                                                            return <div className="slider-image-box">
                                                            <img src={image.image.mediaItemUrl} alt={image.image.altText} className="img-fluid" />
                                                        </div>
                                                        }
                                                    })
                                                }
                                            </div>
                                        }

                                        </div>
                                    </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            }


            {
                titleWImagetitle && 
                <div className="content-text-area">
                    <h3 className="text-center">{titleWImagetitle}</h3>
                </div>
            }

            {
                titleWImageimage && 
                <div className="single-image-caption">
                    <figure className="figure">
                        <a href={titleWImageimage.mediaItemUrl} data-fancybox><img src={titleWImageimage.mediaItemUrl} className="figure-img img-fluid" alt={titleWImageimage.altText} style={{width: "1200px"}} /></a>
                    {
                        titleWImageimageCaption && <figcaption className="figure-caption text-center">{titleWImageimageCaption}</figcaption>
                    }
                    </figure>
                    
                </div>
            }

            {
                titleWImagetext && <div className="content-text-area" dangerouslySetInnerHTML={{ __html: titleWImagetext }}></div>
            }

        </div>
    )
}

export default partnersTab
