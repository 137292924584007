import React from "react"


const OurPeopleTab = ({content, index}) => {
    const tabName = content.nameTab;
    const imageWText = content.ourPeopleimageWText;
    const joinTheTeam = content.joinTheTeam;
    const ourTeam = content.ourTeam;
    const tabs = content.tabs;

    console.log(imageWText);
    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
            {
                (ourTeam.title || ourTeam.items) && 
                <div className="our-team-half-img">
                    <div className="content-area">
                        { ourTeam.title && <h2 className="our-team-title">{ourTeam.title}</h2> }
                        
                        {
                            ourTeam.items.map((item, index) => {
                                if(item) {
                                    return (
                                    <div className="our-team-box">
                                        <div className="photo-area">
                                            { item.image && <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" /> }
                                            
                                        </div>
                                        <div className="text-area">
                                            { item.title && <h2>{item.title}</h2> }
                                            { item.subtitle && <h3>{item.subtitle}</h3> }

                                            { item.text && <div dangerouslySetInnerHTML={{ __html: item.text }}></div> }
                                        </div>
                                    </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            }

            {
                (joinTheTeam.title || joinTheTeam.items) && 
                <div className="join-the-team">
                    <div className="content-area">
                        { joinTheTeam.title && <h2 className="title-text">{joinTheTeam.title}</h2> }
                        
                        {
                            joinTheTeam.items &&
                            <div className="join-team-boxes">
                                {
                                    joinTheTeam.items.map((item, index) => {
                                        if(item) {
                                            return (
                                            <div className="join-team-box">
                                                { item.title && <h3>{item.title}</h3> }
                                                { item.text && <p dangerouslySetInnerHTML={{ __html: item.text }}></p> }

                                                {
                                                    (item.button1 || item.button2) && 
                                                    <div className="button-wrapper">
                                                        { item.button1 && <a href={item.button1.url} target={item.button1.target} className="btn">{item.button1.title}</a> }

                                                        { item.button2 && <a href={item.button2.url} target={item.button2.target} className="btn invert">{item.button2.title}</a> }
                                                        
                                                        
                                                    </div>
                                                }
                                            </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            }


            {
                (imageWText.image || imageWText.title || imageWText.subtitle || imageWText.text) && 
                <div className="our-team-half-img">
                    <div className="content-area larger">
                        <div className="our-team-box">
                            <div className="photo-area">
                                { imageWText && <img src={imageWText.image.mediaItemUrl} alt={imageWText.image.altText} className="img-fluid" /> }
                                
                            </div>
                            <div className="text-area">
                                { imageWText.title && <h2>{imageWText.title}</h2> }
                                { imageWText.subtitle && <h3>{imageWText.subtitle}</h3> }     
                                { imageWText.text && <div dangerouslySetInnerHTML={{ __html: imageWText.text }}></div> }
                            </div>
                        </div>
                    </div>
                </div>
            }


            {
                tabs &&
                <div className="inner-tabs larger">
                    <nav>
                        <div className="nav nav-tabs" id="inner-nav-tab" role="tablist">
                            {
                                tabs.map((item, index) => {
                                    if(item){
                                        return (
                                            <button className={`nav-link ${index == 0 ? 'active' : ''}`} id={"nav-" + slugify(item.titleTab) + 'tab'} data-bs-toggle="tab" data-bs-target={`#nav-${slugify(item.titleTab)}`} type="button" role="tab" aria-controls={`nav-${slugify(item.titleTab)}`} aria-selected="true">{item.titleTab}</button>
                                        )
                                    }
                                })
                            }
                        </div>
                    </nav>
                    <div className="tab-content" id="inner-nav-tabContent">
                        {
                            tabs.map((item, index) => {
                                if(item){
                                    return (
                                        <div className={`tab-pane fade ${index == 0 ? 'show active' : ''}`} id={`nav-${slugify(item.titleTab)}`} role="tabpanel" aria-labelledby={`nav-${slugify(item.titleTab)}-tab`} tabindex="0">
                                            <div className="inner-tab-content">
                                                <div className="team-list">
                                                    {
                                                        item.title && <h2 className="title">{item.title}</h2>
                                                    }
                                                    

                                                    {
                                                        item.items && 
                                                        <div className="team-boxes">
                                                            {
                                                                item.items.map((team, index) => {
                                                                    if(team) {
                                                                        return (
                                                                            <div className="team-box">
                                                                                { team.title && <h3>{team.title}</h3> }
                                                                                { team.subtitle && <h4>{team.subtitle}</h4> }
                                                                                { team.text && <p>{team.text}</p> }
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                           
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default OurPeopleTab
