import React, { useState } from "react"

const BannerwFilter = ({content}) => {
    const subtitle = content.subtitle;
    const backgroundImage = content.backgroundImage;
    const button = content.button;
    const filters = content.filters;
    const image = content.image;
    const text = content.text;
    const title = content.title;
    const items = content.items;

    const [activeAwards, setActiveAwards] = useState('');

    function handleAwards(newValue) {
        setActiveAwards(newValue);
    }

    function hasAwards(value) {
        let hasAward = false;

        if(activeAwards == '') {
            hasAward = true;
        }

        if(activeAwards.toLowerCase() == value.toLowerCase()) {
            hasAward = true;
        }

        return hasAward;
    }

    if(subtitle || backgroundImage || button || filters || image || text || title || items) {
        return (
            <div>
                <section className="banner-affordable open_omaha laures_awards">
                    <div className="background-image">
                        { backgroundImage && <img src={backgroundImage.mediaItemUrl} alt={backgroundImage.altText} className="img-fluid"></img> }
                        
                    </div>
                    <div className="top-area">
                        <div className="image-left">
                            { image && <img src={image.mediaItemUrl} alt={image.altText} /> }
                            
                        </div>
                        <div className="text-box-right">
                            <div className="top-title">
                                <div className="title-right">
                                    { title && <h1 dangerouslySetInnerHTML={{ __html: title }}></h1> }
                                    
                                    
                                </div>
                            </div>
                            { subtitle && <h3 dangerouslySetInnerHTML={{ __html: subtitle }}></h3> }
                        </div>
                    </div>
                    { text && <div className="text-area" dangerouslySetInnerHTML={{ __html: text }}></div> }
                    <div className="btn_area">
                        { button && <a href={button.url} target={button.target}>{button.title}</a> }
                        
                        {
                            filters && 
                            <div className="filter_wrapper">
                                <div className="filter-box">
                                    <select className="form-select" onChange={e => handleAwards(e.target.value)}>
                                        <option selected="" value="">Previous Awards</option>
                                        {
                                            filters.map((item, index) => {
                                                if(item) {
                                                    return (
                                                        <option value={item.text}>{item.text}</option>
                                                    )
                                                }
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        }
                    </div>
                </section>
    
                {
                    items && 
                    <section className="laurels_award">
                        <div className="content_area">
                            <div className="item_wrapper">
                                {
                                    items.map((item, index) => {
                                        if(item){
                                            if(hasAwards(item.year)) {
                                                return (
                                                    <div className="item">
                                                        <div className="img_area">
                                                            { item.image && <img src={item.image.mediaItemUrl} alt={item.altText} /> }
                                                            
                                                        </div>
                                                        <div className="text_area">
                                                            { item.title && <h3>{item.title}</h3>}
                                                            { item.text && <p dangerouslySetInnerHTML={{ __html: item.text }}></p> }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </section>
                }
            </div>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default BannerwFilter
