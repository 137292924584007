import React from "react"

const prototypesTab = ({content, index}) => {
    const tabName = content.tabName;

    const tabs = content.tabs;
    const tabs2 = content.tabs2;
    const titleWImage = content.titleWImage;
    const titleWImages = content.titleWImages;
    const titleWText = content.titleWText;
    
    const titleWTexttitle = titleWText.title;
    const titleWTexttext = titleWText.text;
    const titleWTextsubtitle = titleWText.subtitle;
    
    const titleWImagetitle = titleWImage.title;
    const titleWImagetext = titleWImage.text;
    const titleWImageimage= titleWImage.image;
    const titleWImageimageCaption= titleWImage.imageCaption;
    
    const titleWImagestitle = titleWImages.title;
    const titleWImagestext = titleWImages.text;
    const titleWImagesimages= titleWImages.images;






    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')


    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
            {
                (titleWTexttitle || titleWTextsubtitle || titleWTexttext) && 
                <div className="content-text-area">
                    { titleWTexttitle && <h2 className="text-center">{titleWTexttitle}</h2>}
                    {titleWTextsubtitle && <h3 className="text-center">{titleWTextsubtitle}</h3>}
                    { titleWTexttext && <div className="text-area" dangerouslySetInnerHTML={{ __html: titleWTexttext }}></div>}
                </div>
            }

            {
                tabs &&

                <div className="inner-tabs">
                    <nav className="nav_third">
                    <div className="slider_tab"></div>
                        <div className="nav nav-tabs" id="inner-nav-tab" role="tablist">
                            {
                                tabs.map((item, index) => {
                                    if(item.titleTab) {
                                        return <button className={`nav-link ${index == 0 ? 'active' : ''}`} id={`nav-1-${slugify(item.titleTab)}-tab`} data-bs-toggle="tab" data-bs-target={`#nav-1-${slugify(item.titleTab)}`} type="button" role="tab" aria-controls={`nav-1-${slugify(item.titleTab)}`} aria-selected="true">{item.titleTab}</button>
                                    }
                                })
                            }
                        </div>
                    </nav>
                    <div className="tab-content" id="inner-nav-tabContent">
                        {
                            tabs.map((item, index) => {
                                if(item) {
                                    return (
                                        <div className={`tab-pane fade ${index == 0 ? 'show active' : ''}`} id={`nav-1-${slugify(item.titleTab)}`} role="tabpanel" aria-labelledby={`nav-1-${slugify(item.titleTab)}-tab`} tabindex="0">
                                        <div className="inner-tab-content">
                                            <div className="single-image-caption">
                                                <figure className="figure">
                                                    {
                                                        item.image && <a href={item.image.mediaItemUrl} data-fancybox><img src={item.image.mediaItemUrl} className="figure-img img-fluid" alt={item.image.altText} style={{ width: '1046px' }} /></a>
                                                    }

                                                    {
                                                        item.imageCaption && <figcaption className="figure-caption text-center">{item.imageCaption}</figcaption>
                                                    }
                                                
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            }


            {
                (titleWImagetitle || titleWImagetext) && 
                <div className="content-text-area">
                    { titleWImagetitle && <h3 className="text-center">{titleWImagetitle}</h3> }
                    { titleWImagetext && <div dangerouslySetInnerHTML={{ __html: titleWImagetext }}></div>}
                </div>
            }

            {
                titleWImageimage && 
                <div className="single-image-caption">
                    <figure className="figure">
                        <a href={titleWImageimage.mediaItemUrl} data-fancybox><img src={titleWImageimage.mediaItemUrl} className="figure-img img-fluid" alt={titleWImageimage.altText} style={{width: '1046px'}} /></a>
                        {
                            titleWImageimageCaption && <figcaption className="figure-caption text-center">{titleWImageimageCaption}</figcaption>
                        }
                        
                    </figure>
                </div>
            }


            {
                tabs2 && 
                <div className="inner-tabs">
                    <nav className="nav_four">
                    <div className="slider_tab"></div>
                        <div className="nav nav-tabs" id="inner-nav-tab" role="tablist">
                            {
                                tabs2.map((item, index) => {
                                    if(item.titleTab) {
                                        return <button className={`nav-link ${index == 0 ? 'active' : ''}`} id={`nav-2-${slugify(item.titleTab)}-tab`} data-bs-toggle="tab" data-bs-target={`#nav-2-${slugify(item.titleTab)}`} type="button" role="tab" aria-controls={`nav-2-${slugify(item.titleTab)}`} aria-selected="true">{item.titleTab}</button>
                                    }
                                })
                            }
                        </div>
                    </nav>
                    <div className="tab-content" id="inner-nav-tabContent">
                        {
                            tabs2.map((item, index) => {
                                if(item.titleTab && item.images) {
                                    return (
                                        <div className={`tab-pane fade ${index == 0 ? 'show active' : ''}`} id={`nav-2-${slugify(item.titleTab)}`} role="tabpanel" aria-labelledby={`nav-2-${slugify(item.titleTab)}-tab`} tabindex="0">
                                            <div className="inner-tab-content">
                                                <div className="slider-image">
                                                    {
                                                        item.images.map((item, index) => {
                                                            return (
                                                                <div className="slider-image-box">
                                                                    <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            }

            {
                (titleWImagestitle || titleWImagestext) && 
                <div className="content-text-area">
                    { titleWImagestitle && <h3 className="text-center">{titleWImagestitle}</h3> }
                    { titleWImagestext && <div className="text-area" dangerouslySetInnerHTML={{ __html: titleWImagestext }}></div>}
                </div>
            }

            {
                titleWImagesimages && 
                <div className="three-column-image-caption">
                    {
                        titleWImagesimages.map((item, index) => {
                            return (
                            <figure className="figure">
                                <img src={item.image.mediaItemUrl} alt={item.image.altText} className="figure-img img-fluid" />
                                {
                                    item.caption && <figcaption className="figure-caption text-center">{item.caption}</figcaption>
                                }
                                
                            </figure>
                            )
                        })
                    }
                </div>
            }

        </div>
    )
}

export default prototypesTab
