import React from "react"


const OurPrioritiesTab = ({content, index}) => {
    const tabName = content.nameTab;
    const quote = content.quote;
    const iconWText = content.iconWText;

    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
            {
                (iconWText && iconWText.icons) && 
                <div className="four-column-icon">
                    <div className="content-area">
                        <div className="icons-boxes">
                            {
                                iconWText.icons.map((item, index) => {
                                    if(item) {
                                        return (
                                        <div className="icon-box">
                                            <div className="icon-area">
                                                { item.icon && <img src={item.icon.mediaItemUrl} alt={item.icon.altText} className="img-fluid" /> }
                                            </div>
                                            { item.title && <h3>{item.title}</h3> }
                                            { item.text && <p dangerouslySetInnerHTML={{ __html: item.text }}></p> }
                                        </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            }

            {
                (iconWText && iconWText.text) && 
                <div className="content-text-area space_more" dangerouslySetInnerHTML={{ __html: iconWText.text }}></div>
            }

            {
                (quote.text || quote.author) && 
                <div className="quote-pink">
                    <div className="content-area">
                        <div className="quote-box">
                            { quote.text && <h3>{quote.text}</h3> }
                            { quote.author && <h4>{quote.author}</h4> }                            
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default OurPrioritiesTab
