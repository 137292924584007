import React from "react"
import appStore from '../../assets/images/open_omaha_3.jpg';
import playstore from '../../assets/images/open_omaha_4.jpg';
import pinArt from '../../assets/images/svg/pin-art.svg';

const VenuesTab = ({content, index}) => {
    const tabName = content.nameTab;
    const applyVenue = content.applyVenue;
    const imageWText = content.venuesImage;
    const items = content.items;
    const myVenue = content.myVenue;
    const textWImage = content.textWImage;


    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>

            {
                (applyVenue.backgroundImage || applyVenue.title || applyVenue.subtitle || applyVenue.button) && 
                <div className="join_us">
                    <div className="bg_image">
                        {
                            applyVenue.backgroundImage && 
                            <img src={applyVenue.backgroundImage.mediaItemUrl} alt={applyVenue.backgroundImage.altText} />
                        }
                    </div>
                    <div className="content_area">
                        { applyVenue.title && <h4>{applyVenue.title}</h4> }
                        { applyVenue.subtitle && <p>{applyVenue.subtitle}</p> }
                        {
                            applyVenue.button && 
                            <div className="btn_wrapper">
                                <a href={applyVenue.button.url} target={applyVenue.button.target}>{applyVenue.button.title}</a>
                            </div>
                        }                      
                    </div>
                </div>
            }

            {
                (imageWText.text || imageWText.title || imageWText.linkAppStore || imageWText.linkGooglePlayStore || imageWText.image) &&
                <div className="image_w_text switch_img">
                    <div className="content_area">
                        {
                            imageWText.text && 
                            <div className="top_area" dangerouslySetInnerHTML={{ __html: imageWText.text }}></div>
                        }
                        
                        <div className="left_area">
                            {
                                imageWText.title && <h4>{imageWText.title}</h4>
                            }

                            <div className="text_area" dangerouslySetInnerHTML={{ __html: imageWText.text2 }}></div>

                            <div className="link_wrapper">
                                {
                                    imageWText.linkAppStore &&
                                    <a href={imageWText.linkAppStore.url} target={imageWText.linkAppStore.target}>
                                        <img src={appStore} alt="open omaha 3 jpg" />
                                    </a>
                                }
                                
                                {
                                    imageWText.linkGooglePlayStore &&
                                    <a href={imageWText.linkGooglePlayStore.url} target={imageWText.linkGooglePlayStore.target}>
                                        <img src={playstore} alt="open omaha 3 jpg" />
                                    </a>
                                }
                            </div>

                        </div>
                        <div className="right_area">
                            <div className="img_area">
                                {
                                    imageWText.image && 
                                    <img src={imageWText.image.mediaItemUrl} alt={imageWText.image.altText} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                items && 
                <div className="address_grid">
                    {
                        items.map((item, index) => {
                            if(item) {
                                let show_link = false;
                                if(item.link && item.text) {
                                    show_link = true;
                                }
                                return (
                                <div className="item_grid">
                                    <div className="img_area">
                                        {
                                            item.image && 
                                            <img src={item.image.mediaItemUrl} alt={item.image.altText} />
                                        }
                                    </div>
                                    <div className="icon_area">
                                        <img src={pinArt} alt="svg pin" />
                                        {
                                            item.text && show_link && <a href={item.link.url} target={item.link.target}><span>{item.text}</span> </a>
                                        }
                                        {
                                            item.text && !show_link && <span>{item.text}</span> 
                                        }
                                        
                                    </div>
                                </div>
                                )
                            }
                        })
                    }
                </div>
            }

            {
                (textWImage.title || textWImage.subtitle || textWImage.text || textWImage.linkAppStore || textWImage.linkGooglePlayStore || textWImage.image) && 
                <div className="image_w_text">
                    <div className="content_area">
                        <div className="left_area">
                            { textWImage.title && <h4 className="small-mb">{textWImage.title}</h4> }
                            { textWImage.subtitle && <h5 className="small-mb">{textWImage.subtitle}</h5> }
                            { textWImage.text && <div dangerouslySetInnerHTML={{ __html: textWImage.text }}></div> }


                            <div className="link_wrapper">
                            {
                                    textWImage.linkAppStore &&
                                    <a href={textWImage.linkAppStore.url} target={textWImage.linkAppStore.target}>
                                        <img src={appStore} alt="open omaha 3 jpg" />
                                    </a>
                                }
                                
                                {
                                    textWImage.linkGooglePlayStore &&
                                    <a href={textWImage.linkGooglePlayStore.url} target={textWImage.linkGooglePlayStore.target}>
                                        <img src={playstore} alt="open omaha 3 jpg" />
                                    </a>
                                }
                            </div>
                        </div>
                        <div className="right_area">
                            { textWImage.image && <img src={textWImage.image.mediaItemUrl} alt={textWImage.image.altText} /> }
                            
                        </div>
                    </div>
                </div>
            }


            {
                (myVenue.title || myVenue.text) && 
                <div className="my_venue">
                    <div className="content_area">
                        { myVenue.title  && <h2>{myVenue.title }</h2> }
                        { myVenue.text && <div dangerouslySetInnerHTML={{ __html: myVenue.text }}></div> }
                    </div>
                </div>
            }
        </div>
    )
}

export default VenuesTab
