import React, { useState } from "react";
import pinArt from "../../assets/images/svg/pin-art.svg" ;

const OurMuralsTab = ({content, index}) => {

   const [activeProject, setActiveProject] = useState('');
   const [activeArtist, setActiveArtist] = useState('');

    const tabName = content.nameTab;
    const filterProject = content.filterProject;
    const instagramGallery = content.instagramGallery;
    const titleWMap = content.titleWMap;
    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');

    function handleProject(newValue) {
        setActiveProject(newValue);
        console.log(activeProject);
    }
    
    function handleArtist(newValue) {
        setActiveArtist(newValue)
    }

    function hasProject(value) {
        let hasProject = false;

        if(activeProject == '') {
            hasProject = true;
        }

        if(activeProject.toLowerCase() == value.toLowerCase()) {
            hasProject = true;
        }

        return hasProject;
    }

    function hasArtist(value) {
        let hasArtist = false;

        if(activeArtist == '') {
            hasArtist = true;
        }

        if(activeArtist.toLowerCase() == value.toLowerCase()) {
            hasArtist = true;
        }

        return hasArtist;
    }

    function clearFilters(){   
        setActiveArtist('');
        setActiveProject('');
      }
    
    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>

            {
                (titleWMap.title || titleWMap.customCode ) && 
                <div className="map_container">
                    { titleWMap.title && <h2 className="text-center">Our Murals</h2> }
                    { 
                        titleWMap.customCode && 
                        <div className="map_area">
                            <div dangerouslySetInnerHTML={{ __html: titleWMap.customCode }}></div>
                        </div>
                    }
                </div>
            }

            {
                (filterProject.filter1New) &&
                <div className="filter_container">
                    <div className="filter-area">
                        <span className="label_filter">Filter By:</span>
                        {
                            filterProject.filter1New &&
                            <div className="filter-box">
                                <select value={activeProject} onChange={e => handleProject(e.target.value)} className="form-select">
                                    <option value="">All Projects</option>
                                    {
                                        filterProject.filter1New.map((item, index) => {
                                            return <option value={item.textNew}>{item.textNew}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }
                        {
                            filterProject.filter2 &&
                            <div className="filter-box">
                                <select value={activeArtist} onChange={e => handleArtist(e.target.value)} className="form-select">
                                    <option value="">Artist</option>
                                    {
                                        filterProject.filter2.map((item, index) => {
                                            return <option value={item.text}>{item.text}</option>
                                        })
                                    }
                                </select>
                            </div>
                        }
                        <div className="filter-reset" onClick={() => clearFilters()}>
                            <div className={`filter-reset-button ${activeProject == '' && activeArtist == '' ? 'invisible' : ''}`}>Clear Filters</div>
                        </div>
                    </div>
                    {
                        filterProject.items && 
                        <div className="project_wrapper">
                            {
                                filterProject.items.map((item, index) => {
                                    if(item) {
                                        if(hasProject(item.nameProject)) {                                     
                                            return (
                                                <div>
                                                    { item.nameProject && <h2 className="text-center">{item.nameProject}</h2> }
                                                    
                                                    {
                                                        item.projects && 
                                                        <div className="projects_container">
                                                            {
                                                                item.projects.map((project, index) => {
                                                                    if(project) {
                                                                        if(hasArtist(project.artistName)) {
                                                                            return (
                                                                                <div className="item">
                                                                                    {
                                                                                        (project.artistName || project.muralName) && 
                                                                                        <h5>{ project.artistName && <span>{project.artistName} </span> } {project.muralName}</h5>
                                                                                    }
        
                                                                                    {
                                                                                        project.image && 
                                                                                        <div className="img_area">
                                                                                            <img src={project.image.mediaItemUrl} alt={project.image.altText} />
                                                                                        </div>
                                                                                    }
        
                                                                                    {
                                                                                        project.location &&
                                                                                        <div className="location"><span className="icon_area"><img src={pinArt} alt="pin" /></span> <span className="text">{project.location}</span></div>
                                                                                    }

                                                                                    {
                                                                                        project.text && 
                                                                                        <div className="text_html" dangerouslySetInnerHTML={{ __html: project.text }}>

                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                    
                                                </div>
                                            )
                                        }
                                    }
                                })
                            }
                        </div>
                    }
                </div>
            }

            {
                (instagramGallery.title || instagramGallery.link || instagramGallery.embedCode) && 
                <div className="gallery_ig">
                    <div className="content_wrapper">
                        { instagramGallery.title && <h3 className="text-center">{instagramGallery.title}</h3> }
                        {
                            instagramGallery.link && 
                            <a href={instagramGallery.link.url} target={instagramGallery.link.target} className="ig_link">{instagramGallery.link.title}</a>
                        }

                        {
                            instagramGallery.embedCode && 
                            <div className="image_wrap">
                                <div className="slider-image_ig" dangerouslySetInnerHTML={{ __html: instagramGallery.embedCode }}>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default OurMuralsTab
