import React from "react"

const Recipients = ({content}) => {
    const title = content.title;
    const items = content.items;

    if(title || items) {
        return (
            <section className="recipients">
                <div className="content_area">
                    <div className="text_area">
                        { title && <h2>{title}</h2> }
                        
                    </div>
    
                    {
                        items &&
                        <div className="items">
                            {
                                items.map((item, index) => {
                                    if(item) {
                                        return (
                                        <div className="item">
                                            <div className="left_area">
                                                <div className="img_area">
                                                    { item.image && <img src={item.image.mediaItemUrl} alt={item.image.altText} /> }
                                                </div>
                                            </div>
                                            <div className="right_area">
                                                {
                                                    item.title2 && <h3>{item.title2}</h3>
                                                }
                                                {
                                                    item.text && 
                                                    <div className="text_area">
                                                        <div classNameName="text_html" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="text_image">
                                                { item.title && <h4>{item.title}</h4> }
                                                { item.subtitle && <p>{item.subtitle}</p> }
    
                                                {
                                                    item.images && 
                                                    <div className={`img_area ${item.images.length == 1 ? 'center' : ''}`}>
                                                        {
                                                             item.images.map((item, index) => {
                                                                if(item) {
                                                                    if(item.imageCaption) {
                                                                        return (
                                                                            <div className="with_caption">
                                                                                <img src={item.image.mediaItemUrl} alt={item.image.altText} />
                                                                                <span>{item.imageCaption}</span>
                                                                            </div>
                                                                        )
                                                                    } else {
    
                                                                    }
                                                                    return (
                                                                        <img src={item.image.mediaItemUrl} alt={item.image.altText} />
                                                                    )
                                                                }
                                                             })
                                                        }                                                    
                                                    </div>
                                                }                                            
                                            </div>
                                        </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    }
    
                </div>
            </section>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default Recipients
