import React from "react"

const getInvolved = ({content}) => {
    const title = content.title;
    const sliders = content.sliders;

    if(title || sliders) {
        return (
            <section className="get-involved-award">
            <div className="content-area">
                {
                    title && 
                    <h2 className="title-text">{title}</h2>
                }
    
                {
                    sliders && 
                    <div className="award-sliders">
                        {
                            sliders.map((item, index) => {
                                if(item) {
                                    if(item.themeColor == 'light') {
                                        return (
                                        <div className="award-slider">
                                            <div className="award-slider-box">
                                                { (item.title || item.titleColor) && <h3>{item.title} <a href={item.button.url} target={item.button.target} style={{color: "#AAB244"}}>{item.titleColor}</a></h3> }
                                                { item.image && <img src={item.image.mediaItemUrl} alt={item.image.altText}className="img-fluid" /> }
                                                
                                                { item.button && <a href={item.button.url} target={item.button.target} className="btn" style={{backgroundColor: "#AAB244"}}>{item.button.title}</a> }
                                                
                                            </div>
                                        </div>
                                        )
                                    } else if(item.themeColor == 'green') {
                                        return (
                                        <div className="award-slider">
                                            <div className="award-slider-box">
                                                { (item.title || item.titleColor) && <h3>{item.title} <a href={item.button.url} target={item.button.target} style={{color: "#55B255"}}>{item.titleColor}</a></h3> }
                                                { item.image && <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" /> }
                                                
                                                { item.button && <a href={item.button.url} target={item.button.target} className="btn" style={{backgroundColor: "#55B255"}}>{item.button.title}</a> }
                                            </div>
                                        </div>
                                        )
                                    } else {
                                        return (
                                        <div className="award-slider">
                                            <div className="award-slider-box">
                                                { (item.title || item.titleColor) && <h3>{item.title} <a href={item.button.url} target={item.button.target} style={{color: "#E5B233"}}>{item.titleColor}</a></h3> }
                                                { item.image && <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" /> }
                                                
                                                { item.button && <a href={item.button.url} target={item.button.target} className="btn" style={ {backgroundColor: "#E5B233"} }>{item.button.title}</a> }
                                            </div>
                                        </div>
                                        )
                                    }
                                }
                            })
                        }
                    </div>
                }
    
            </div>
            </section>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default getInvolved
