import React from "react"

const MakeAContribution = ({content}) => {
    const title = content.title;
    const text = content.text;
    const customCode = content.customCode;

    if(title || text || customCode) {
        return (
            <section className="make-contribution">
                <div className="content-area">
                    <div className="text-area">
                        { title && <h3>{title}</h3> }
                        { text && <p>{text}</p> }
                    </div>
                    <div className="donation-module empty" dangerouslySetInnerHTML={{__html: customCode}} >
                       
                    </div>
                </div>
            </section>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default MakeAContribution
