import React from "react"
import PurposeTab from "./purposeTab"
import PartnersTab from "./partnersTab"
import ProcessTab from "./processTab"
import DesignTab from "./designTab"
import PrototypesTab from "./prototypesTab"
import EmptyTab from "./emptyTab"
import OurMuralsTab from "./ourMuralsTab"
import OurArtistsTab from "./ourArtistsTab"
import ProgramTeamTab from "./programTeamTab"
import ArtYouGalleryTab from "./artYouGalleryTab"
import FaqsTab from "./faqsTab"
import AboutTab from "./aboutTab"
import VenuesTab from "./venuesTab"
import SponsorTab from "./sponsorTab"
import PastEventTab from "./pastEventTab"
import OurPeopleTab from "./ourPeopleTab"
import OurMissionTab from "./ourMissionTab"
import OurPrioritiesTab from "./ourPrioritiesTab"

const containerTabs = ({content, flexContent}) => {
    const themeColor = content.themeColor;
    const tabs = content.tabs;
    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');
    if(tabs) {
        return (
            tabs && 
            <section className={`content-toggle-area ${themeColor == 'pink' && 'theme_red'} ${themeColor == 'orange' && 'theme_orange'} ${themeColor == 'dark_blue' && 'theme_dark_blue'}`}>
  
                <nav className="nav_first">
                    {
                        themeColor == 'blue' && <div className="slider_tab"></div>
                    }
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        {
                            tabs.map((item, index) => {
                                const tabSlug = slugify(item.title);
                                return <button className={`nav-link ${index == 0 ? 'active' : ''}`} id={`nav-${tabSlug}-tab`} data-bs-toggle="tab" data-bs-target={"#nav-" + tabSlug} type="button" role="tab" aria-controls={"#nav-" + tabSlug} aria-selected="true">{item.title}</button>
                            })
                        }
                    </div>
                </nav>
                <div className="tab-content" id="nav-tabContent">
                    {flexContent && flexContent.flexibleContent.map((layout, index) => {
                        console.log(layout.fieldGroupName);
                        if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_PurposeTab") {
                            return ( <PurposeTab content={layout} key={index} index={index} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_PartnersTab") {
                            return ( <PartnersTab content={layout} key={index} index={index} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_ProcessTab") {
                            return ( <ProcessTab content={layout} key={index} index={index} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_DesignTab") {
                            return ( <DesignTab content={layout} key={index} index={index} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_PrototypesTab") {
                            return ( <PrototypesTab content={layout} key={index} index={index} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_EmptyTab") {
                            return ( <EmptyTab content={layout} key={index} index={index} /> )
                        }  else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_OurMuralsTab") {
                            return ( <OurMuralsTab content={layout} key={index} index={index} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_OurArtistsTab") {
                            return ( <OurArtistsTab content={layout} key={index} index={index} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_ProgramTeamTab") {
                            return ( <ProgramTeamTab content={layout} key={index} index={index} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_ArtYouGalleryTab") {
                            return ( <ArtYouGalleryTab content={layout} key={index} index={index} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_FaqsTab") {
                            return ( <FaqsTab content={layout} key={index} index={index} /> )
                        }  else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_AboutTab") {
                            return ( <AboutTab content={layout} key={index} index={index} /> )
                        }  else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_VenuesTab") {
                            return ( <VenuesTab content={layout} key={index} index={index} /> )
                        }  else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_SponsorsTab") {
                            return ( <SponsorTab content={layout} key={index} index={index} /> )
                        }  else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_PastEventsTab") {
                            return ( <PastEventTab content={layout} key={index} index={index} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_OurPeopleTab") {
                            return ( <OurPeopleTab flexContent={flexContent} key={index} index={index} content={layout} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_OurMissionTab") {
                            return ( <OurMissionTab flexContent={flexContent} key={index} index={index} content={layout} /> )
                        } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_OurPrioritiesTab") {
                            return ( <OurPrioritiesTab flexContent={flexContent} key={index} index={index} content={layout} /> )
                        } 
                    }) 
                    }
                </div>
            </section>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default containerTabs
