import React, { useState } from "react"
import arrBottom from '../../assets/images/svg/open_omaha_arr_bottom.svg';


const BannerwFilter = ({content}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const title = content.title;
    const faqs = content.faqs;

    if(title || faqs) {
        return (
            <div className="about_open_omaha theme_yellow">
                <div className="content_area">
                    <div className="text_area">
                        { title && <h2>{title}</h2> }
                        
                        {
                            faqs && 
                            <div className="toggle_area">
    
                                {
                                    faqs.map((item, index) => {
                                        const isActive = index === activeIndex
    
                                        if(item) {
                                            return (
    
                                            <div className={`toggle_item ${isActive ? 'open' : ''}`} onClick={() => {
                                                if(activeIndex == index) {
                                                    setActiveIndex('toggle')
                                                } else {
                                                    setActiveIndex(index)
                                                }
                                            }}>
                                                <div className="toggle_header">
                                                    { item.question && <span>{item.question}</span> }
                                                    
                                                    <div className="icon">
                                                        <img src={arrBottom} alt="svg arrow" />
                                                    </div>
                                                </div>
                                                <div className="toggle_content">
                                                    { item.answer && <p>{item.answer}</p> }
                                                </div>
                                            </div>
                                            )
                                        }
                                    })
                                }
    
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default BannerwFilter
