import React, { useState } from "react"
import appStore from '../../assets/images/open_omaha_3.jpg';
import playstore from '../../assets/images/open_omaha_4.jpg';
import arrBottom from '../../assets/images/svg/open_omaha_arr_bottom.svg';

const AboutTab = ({content, index}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const tabName = content.nameTab;


    const aboutOpenOmaha = content.aboutOpenOmaha;
    const applyVenue = content.applyVenue;
    const becomeASponsor = content.becomeASponsor;
    const imageWText = content.aboutImage;
    const joinUs = content.joinUs;
    const textWImage = content.textWImage;

    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-');
    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
            {
                (textWImage.title || textWImage.text || textWImage.linkAppStore || textWImage.image) && 
                <div className="image_w_text">
                    <div className="content_area">
                        <div className="left_area">
                            { textWImage.title && <h4>{textWImage.title}</h4> }
                            { textWImage.text && <p>{textWImage.text}</p> }
                            
                            {
                                textWImage.linkAppStore && textWImage.linkGooglePlayStore &&
                                <div className="link_wrapper">
                                    {
                                        textWImage.linkAppStore &&
                                        <a href={textWImage.linkAppStore.url} target={textWImage.linkAppStore.target}>
                                            <img src={appStore} alt="open omaha 3 jpg" />
                                        </a>
                                    }
                                    
                                    {
                                        textWImage.linkGooglePlayStore &&
                                        <a href={textWImage.linkGooglePlayStore.url} target={textWImage.linkGooglePlayStore.target}>
                                            <img src={playstore} alt="open omaha 3 jpg" />
                                        </a>
                                    }

                                </div>
                            }
                        </div>
                        <div className="right_area">
                            {
                                textWImage.image && 
                                <img src={textWImage.image.mediaItemUrl} alt={textWImage.image.altText} />
                            }
                        </div>
                    </div>
                </div>
            }

            {
                (joinUs.backgroundImage || joinUs.title || joinUs.subtitle || joinUs.text) && 
                <div className="join_us">
                    <div className="bg_image">
                        {
                            joinUs.backgroundImage && 
                            <img src={joinUs.backgroundImage.mediaItemUrl} alt={joinUs.backgroundImage.altText} />
                        }
                    </div>
                    <div className="content_area">
                        {
                            joinUs.title && 
                            <h4 dangerouslySetInnerHTML={{ __html: joinUs.title }}></h4>
                        }
                        {
                            joinUs.subtitle && 
                            <h5 dangerouslySetInnerHTML={{ __html: joinUs.subtitle }}></h5>
                        }
                        {
                            joinUs.text && 
                            <p dangerouslySetInnerHTML={{ __html: joinUs.text }}></p>
                        }
                    </div>
                </div>
            }

            {
                (applyVenue.title || applyVenue.subtitle || applyVenue.text || applyVenue.infoSessions || applyVenue.button ) && 
                <div className="apply_venue">
                    <div className="content_area">
                        { applyVenue.title && <h2>{applyVenue.title}</h2> }
                        { applyVenue.subtitle && <h5>{applyVenue.subtitle}</h5> }
                        {
                            applyVenue.text && 
                            <div dangerouslySetInnerHTML={{ __html: applyVenue.text }}></div>
                        }

                        {
                            applyVenue.infoSessions && 
                            <p>
                                {
                                    applyVenue.infoSessions.map((item, index) => {
                                        if(item) {
                                            if(index + 1 == applyVenue.infoSessions.length) {
                                                return (
                                                    <span>{item.title} { item.link && <a href={item.link.url} target={item.link.target}>{item.link.title}</a> }</span>
                                                )
                                            } else {
                                                return (
                                                    <span>{item.title} { item.link && <a href={item.link.url} target={item.link.target}>{item.link.title}</a> } <br /></span>
                                                )
                                            }
                                        }
                                    })
                                }
                                </p>
                        }

                        {
                            applyVenue.button && 
                            <div className="btn_area">
                                <a href={applyVenue.button.url} target={applyVenue.button.target}>{applyVenue.button.title}</a>
                            </div>
                        }
                    </div>
                </div>
            }

            {
                (becomeASponsor.bakcgroundImage || becomeASponsor.title || becomeASponsor.subtitle || becomeASponsor.button ) && 
                <div className="become_a_sponsor">
                    <div className="background-image">
                        {
                            becomeASponsor.bakcgroundImage && 
                            <img src={becomeASponsor.bakcgroundImage.mediaItemUrl} alt={becomeASponsor.bakcgroundImage.altText} className="img-fluid" />
                        }
                    </div>
                    <div className="content_area">
                        <div className="text_area">
                            { becomeASponsor.title && <h2>{becomeASponsor.title}</h2> }
                            { becomeASponsor.subtitle && <p>{becomeASponsor.subtitle}</p> }

                            {
                                becomeASponsor.button &&
                                <a href={becomeASponsor.button.url} target={becomeASponsor.button.target}>{becomeASponsor.button.title}</a>
                            }
                        </div>
                    </div>
                </div>
            }
            {
                (imageWText.image || imageWText.title || imageWText.text) && 
                <div className="enjoy_free_rides">
                    <div className="content_area">
                        <div className="left_area">
                            <div className="img_area">
                                {
                                    imageWText.image && 
                                    <img src={imageWText.image.mediaItemUrl} alt={imageWText.image.altText} />
                                }
                            </div>
                        </div>
                        <div className="right_area">
                            { imageWText.title && <h2>{imageWText.title}</h2> }
                            { imageWText.text && <p>{imageWText.text}</p> }
                        </div>
                    </div>
                </div>
            }

            {
                (aboutOpenOmaha.backgroundImage || aboutOpenOmaha.title || aboutOpenOmaha.faqs) && 
                <div className="about_open_omaha">
                    <div className="background-image">
                        {
                            aboutOpenOmaha.backgroundImage && 
                            <img src={aboutOpenOmaha.backgroundImage.mediaItemUrl} alt={aboutOpenOmaha.backgroundImage.altText} className="img-fluid" />
                        }
                    </div>
                    <div className="content_area">
                        <div className="text_area">
                            { aboutOpenOmaha.title && <h2>{aboutOpenOmaha.title}</h2> }
                            {
                                aboutOpenOmaha.faqs && 
                                <div className="toggle_area">

                                    {
                                        aboutOpenOmaha.faqs.map((item, faq_index) => {
                                            const isActive = faq_index === activeIndex
                                            if(item) {
                                                return (

                                                <div className={`toggle_item ${isActive ? 'open' : ''}`} onClick={() => {
                                                    if(activeIndex == faq_index) {
                                                        setActiveIndex('toggle')
                                                    } else {
                                                        setActiveIndex(faq_index)
                                                    }
                                                }}>
                                                    <div className="toggle_header">
                                                        { item.question && <span>{item.question}</span> }
                                                        
                                                        <div className="icon">
                                                            <img src={arrBottom} alt="svg arrow" />
                                                        </div>
                                                    </div>
                                                    <div className="toggle_content">
                                                        { item.answer && <p>{item.answer}</p> }
                                                    </div>
                                                </div>
                                                )
                                            }
                                        })
                                    }

                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default AboutTab
