import React from "react"


const OurMissionTab = ({content, index}) => {
    const tabName = content.nameTab;
    const textWImage = content.textWImage;
    const titleWText = content.titleWText;

    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
            {
                (titleWText.backgroundImage || titleWText.title || titleWText.text) && 
                <div className="join_us our-mission">
                    <div className="bg_image">
                        { titleWText && <img src={titleWText.backgroundImage.mediaItemUrl} alt={titleWText.backgroundImage.altText} /> }
                        
                    </div>
                    <div className="content_area">
                        { titleWText && <h4>{titleWText.title}</h4> }
                        { titleWText.text && <p dangerouslySetInnerHTML={{ __html: titleWText.text }}></p> }
                    </div>
                </div>
            }

            {
                (textWImage && textWImage.title) && 
                <div className="content-text-area space_more">
                    <h2 className="text-center">{textWImage.title}</h2>
                </div>
            }

            {
                textWImage && textWImage.items && textWImage.items.map((item, index) => {
                    if(item) {
                        return (
                            <div className={`image_w_text half-image ${item.switchContent && 'revert'}`}>
                                <div className="content_area">
                                    <div className="left_area">
                                        { item.title && <h2>{item.title}</h2> }
                                        { item.text && <p dangerouslySetInnerHTML={{ __html: item.text }}></p> }
                                    </div>
                                    <div className="right_area">
                                        { item.image && <img src={item.image.mediaItemUrl} alt={item.image.altText} /> }
                                        
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

export default OurMissionTab
