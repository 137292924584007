import React from "react"

const purposeTab = ({content, index}) => {
    const tabName = content.tabName;

    const textArea = content.textArea;
    const textAreasubtitle = textArea.subtitle;
    const textAreatext = textArea.text;
    const textAreatitle = textArea.title;


    const fourImagesWButton = content.fourImagesWButton;
    const fourImagesWButtonbutton = fourImagesWButton.button;
    const fourImagesWButtonimages = fourImagesWButton.images;


    const fullWidthImage = content.fullWidthImage;
    const fullWidthImageimage = fullWidthImage.image;

    const textWAuthor = content.textWAuthor;
    const textWAuthorname = textWAuthor.name;
    const textWAuthorposition = textWAuthor.position;
    const textWAuthortext = textWAuthor.text;

    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')


    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
            {
               ( textAreatitle || textAreasubtitle || textAreatext) &&
                <div className="content-text-area">
                    { textAreatitle && <h2 className="text-center">{textAreatitle}</h2>}
                    { textAreasubtitle && <h6>{textAreasubtitle}</h6>}
                    { textAreatext && <div className="text-area" dangerouslySetInnerHTML={{ __html: textAreatext }}></div>}
                </div>
            }

            {fullWidthImageimage && 
                <div className="full-width-image">
                    <img src={fullWidthImageimage.mediaItemUrl} alt={fullWidthImageimage.altText} className="img-fluid w-100" />
                </div>
            }

            {
                (textWAuthortext || textWAuthorname || textWAuthorposition) &&
                <div className="content-text-area">
                    { textWAuthortext && <div className="text-area" dangerouslySetInnerHTML={{ __html: textWAuthortext }}></div>}
                    { textWAuthorname && <h6 className="blue mb-0">{textWAuthorname}</h6>}
                    { textWAuthorposition && <h6>{textWAuthorposition}</h6>}                
                </div>
            }

            {
                fourImagesWButtonimages && 
                <div className="four-image">
                    {
                        fourImagesWButtonimages.map((item, index) => {
                            return <a href={item.image.mediaItemUrl} data-fancybox><img src={item.image.mediaItemUrl}  alt={item.image.altText}  className="img-fluid" /></a>
                        })
                    }
                </div>
            }

            {
                fourImagesWButtonbutton && 
                <div className="button-center">
                    <a href={fourImagesWButtonbutton.url} target={fourImagesWButtonbutton.target} className="btn">{fourImagesWButtonbutton.title}</a>
                </div>
            }

        </div>
    )
}

export default purposeTab
