import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"

import BannerAffordable from "../components/flexible/bannerAffordable"
import ContainerTabs from "../components/flexible/containerTabs"
import Recipients from "../components/flexible/recipients"
import Logos from "../components/flexible/logos"
import BannerwFilter from "../components/flexible/bannerwFilter"
import Faqs from "../components/flexible/faqs"
import BannerAboutUs from "../components/flexible/bannerAboutUs"
import EngageWork from "../components/flexible/engageWork"
import GetInvolved from "../components/flexible/getInvolved"
import MakeAContribution from "../components/flexible/makeAContribution"


const ourProject = props => {
    const {
        data: {
            wpgraphql: { ourProject },
        },
    } = props
    const { title, flexContent, slug, id } = ourProject

    return (
        <>
            <Seo title={`${title}`} />
            { flexContent && flexContent.flexibleContent.map((layout, index) => {
                if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_BannerAffordable") {
                    return ( <BannerAffordable content={layout} key={index} index={index} id={slug} page={id} /> )
                } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_Tabs") {
                    return ( <ContainerTabs flexContent={flexContent} content={layout} key={index} index={index} id={slug} page={id} /> )
                } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_Recipients") {
                    return ( <Recipients flexContent={flexContent} content={layout} key={index} index={index} id={slug} page={id} /> )
                }  else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_Logos") {
                  return ( <Logos flexContent={flexContent} content={layout} key={index} index={index} id={slug} page={id} /> )
                } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_BannerWFilter") {
                  return ( <BannerwFilter flexContent={flexContent} content={layout} key={index} index={index} id={slug} page={id} /> )
                } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_Faqs") {
                  return ( <Faqs flexContent={flexContent} content={layout} key={index} index={index} id={slug} page={id} /> )
                } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_BannerAboutUs") {
                  return ( <BannerAboutUs flexContent={flexContent} content={layout} key={index} index={index} id={slug} page={id} /> )
                } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_MakeAContribution") {
                  return ( <MakeAContribution flexContent={flexContent} content={layout} key={index} index={index} id={slug} page={id} /> )
                } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_EngageWork") {
                  return ( <EngageWork flexContent={flexContent} content={layout} key={index} index={index} id={slug} page={id} /> )
                } else if(layout.fieldGroupName === "OurProject_Flexcontent_FlexibleContent_GetInvolved") {
                  return ( <GetInvolved flexContent={flexContent} content={layout} key={index} index={index} id={slug} page={id} /> )
                } 
            })
            }

        </>
    )
}

export default ourProject

export const pageQuery = graphql`
query GET_PAGES($id: ID!) {
    wpgraphql {
      ourProject(id: $id) {
        title
        content
        uri
        slug
        id
        flexContent {
          flexibleContent {
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_EngageWork {
              fieldGroupName
              title
              backgroundImage {
                altText
                mediaItemUrl
              }
              button {
                target
                title
                url
              }
              items {
                text
                title
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_GetInvolved {
              fieldGroupName
              title
              sliders {
                titleColor
                title
                themeColor
                button {
                  target
                  title
                  url
                }
                image {
                  altText
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_OurPeopleTab {
              fieldGroupName
              nameTab
              ourPeopleimageWText: imageWText {
                subtitle
                text
                title
                image {
                  altText
                  mediaItemUrl
                }
              }
              joinTheTeam {
                items {
                  title
                  text
                  button1 {
                    target
                    title
                    url
                  }
                  button2 {
                    title
                    url
                    target
                  }
                }
                title
              }
              ourTeam {
                items {
                  title
                  text
                  subtitle
                  image {
                    altText
                    mediaItemUrl
                  }
                }
                title
              }
              tabs {
                title
                titleTab
                items {
                  subtitle
                  text
                  title
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_OurPrioritiesTab {
              fieldGroupName
              nameTab
              iconWText {
                icons {
                  text
                  title
                  icon {
                    altText
                    mediaItemUrl
                  }
                }
                text
              }
              quote {
                author
                text
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_OurMissionTab {
              fieldGroupName
              nameTab
              textWImage {
                items {
                  title
                  text
                  switchContent
                  image {
                    altText
                    mediaItemUrl
                  }
                }
                title
              }
              titleWText {
                text
                title
                backgroundImage {
                  altText
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_BannerAboutUs {
              fieldGroupName
              pretitle
              backgroundImage {
                altText
                mediaItemUrl
              }
              text
              title
              images {
                image {
                  altText
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_MakeAContribution {
              customCode
              fieldGroupName
              text
              title
            }
            ... on  WPGraphQL_OurProject_Flexcontent_FlexibleContent_BannerWFilter {
              fieldGroupName
              subtitle
              backgroundImage {
                altText
                mediaItemUrl
              }
              button {
                target
                title
                url
              }
              filters {
                text
              }
              image {
                altText
                mediaItemUrl
              }
              text
              title
              items {
                text
                title
                year
                image {
                  altText
                  mediaItemUrl
                }
              }
            }
            ... on  WPGraphQL_OurProject_Flexcontent_FlexibleContent_Faqs {
              fieldGroupName
              title
              faqs {
                answer
                question
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_Recipients {
              fieldGroupName
              title
              items {
                subtitle
                text
                title
                title2
                image {
                  altText
                  mediaItemUrl
                }
                images {
                  imageCaption
                  image {
                    altText
                    mediaItemUrl
                  }
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_Logos {
              fieldGroupName
              title
              logosLogo: logos {
                logo {
                  altText
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_FaqsTab {
              fieldGroupName
              nameTab
              title
              leftFaqsNew {
                answerLeft
                questionLeft
              }
              rightFaqs {
                answer
                question
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_ProgramTeamTab {
              fieldGroupName
              nameTab
              text
              titleWText {
                text
                title
              }
              teams {
                name
                text
                image {
                  altText
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_OurArtistsTab {
              fieldGroupName
              nameTab
              titleWText {
                text
                title
              }
              items {
                instagramLink
                text
                title
                websiteLink
                image {
                  altText
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_ArtYouGalleryTab {
              fieldGroupName
              nameTab
              title
              images {
                image {
                  altText
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_OurMuralsTab {
              fieldGroupName
              nameTab
              filterProject {
                filter1New {
                  textNew
                }
                filter2 {
                  text
                }
                items {
                  nameProject
                  projects {
                    artistName
                    location
                    muralName
                    image {
                      altText
                      mediaItemUrl
                    }
                    text
                  }
                }
              }
              instagramGallery {
                embedCode
                link {
                  target
                  title
                  url
                }
                title
              }
              titleWMap {
                customCode
                title
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_BannerAffordable {
              fieldGroupName
              subtitle
              backgroundImage {
                mediaItemUrl
                altText
              }
              button {
                title
                url
                target
              }
              highlightText
              switchContent
              images {
                image {
                  altText
                  mediaItemUrl
                }
              }
              image2 {
                mediaItemUrl
                altText
              }
              text
              title
              titleSmall
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_PurposeTab {
              fieldGroupName
              tabName
              textArea {
                subtitle
                text
                title
              }
              fourImagesWButton {
                button {
                  target
                  title
                  url
                }
                images {
                  image {
                    altText
                    mediaItemUrl
                  }
                }
              }
              fullWidthImage {
                image {
                  altText
                  mediaItemUrl
                }
              }
              textWAuthor {
                name
                position
                text
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_PartnersTab {
              fieldGroupName
              tabName
              affordableByDesignTeam {
                subtitle
                title
                images {
                  image {
                    altText
                    mediaItemUrl
                  }
                }
              }
              textWImage {
                image {
                  altText
                  mediaItemUrl
                }
                imageCaption
                text
              }
              textWImage2 {
                image {
                  altText
                  mediaItemUrl
                }
                imageCaption
                text
              }
              textWTabs {
                tabs {
                  images {
                    image {
                      altText
                      mediaItemUrl
                    }
                  }
                  text
                  titleTab
                }
                text
              }
              textWThreeColumnsImage {
                images {
                  imageCaption
                  image {
                    altText
                    mediaItemUrl
                  }
                }
                text
                title
              }
              titleWImage {
                imageCaption
                text
                title
                image {
                  altText
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_ProcessTab {
              fieldGroupName
              tabName
              goals {
                images {
                  image {
                    altText
                    mediaItemUrl
                  }
                }
              }
              preferredProgram {
                programs {
                  image {
                    altText
                    mediaItemUrl
                  }
                }
                text
                title
              }
              titleWText {
                subtitle
                text
                title
              }
              zoning {
                text
                title
                image {
                  altText
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_DesignTab {
              fieldGroupName
              tabName
              columnImages {
                image {
                  altText
                  mediaItemUrl
                }
                subtitle
                title
              }
              imageWText {
                image {
                  altText
                  mediaItemUrl
                }
                text
              }
              textWImage {
                subtitle
                text
                text2
                text3
                title
                title2
                image {
                  altText
                  mediaItemUrl
                }
              }
              threeColumnsText {
                text
                title
                columns {
                  text
                  title
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_PrototypesTab {
              fieldGroupName
              tabName
              tabs {
                image {
                  altText
                  mediaItemUrl
                }
                imageCaption
                titleTab
              }
              tabs2 {
                images {
                  image {
                    altText
                    mediaItemUrl
                  }
                }
                titleTab
              }
              titleWImage {
                imageCaption
                text
                title
                image {
                  altText
                  mediaItemUrl
                }
              }
              titleWImages {
                text
                title
                images {
                  caption
                  image {
                    altText
                    mediaItemUrl
                  }
                }
              }
              titleWText {
                subtitle
                text
                title
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_Tabs {
              fieldGroupName
              themeColor
              tabs {
                title
              }
            }

            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_EmptyTab {
              fieldGroupName
              nameTab
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_AboutTab {
              nameTab
              fieldGroupName
              aboutOpenOmaha {
                backgroundImage {
                  altText
                  mediaItemUrl
                }
                title
                faqs {
                  question
                  answer
                }
              }
              applyVenue {
                title
                text
                subtitle
                button {
                  target
                  title
                  url
                }
                infoSessions {
                  link {
                    target
                    title
                    url
                  }
                  title
                }
              }
              becomeASponsor {
                subtitle
                title
                bakcgroundImage {
                  altText
                  mediaItemUrl
                }
                button {
                  target
                  title
                  url
                }
              }
              aboutImage: imageWText {
                text
                title
                image {
                  altText
                  mediaItemUrl
                }
              }
              joinUs {
                subtitle
                text
                title
                backgroundImage {
                  altText
                  mediaItemUrl
                }
              }
              textWImage {
                image {
                  altText
                  mediaItemUrl
                }
                linkAppStore {
                  target
                  title
                  url
                }
                linkGooglePlayStore {
                  title
                  url
                  target
                }
                text
                title
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_VenuesTab {
              fieldGroupName
              nameTab
              applyVenue {
                subtitle
                title
                backgroundImage {
                  altText
                  mediaItemUrl
                }
                button {
                  target
                  title
                  url
                }
              }
              venuesImage: imageWText {
                text
                text2
                title
                image {
                  altText
                  mediaItemUrl
                }
                linkAppStore {
                  target
                  title
                  url
                }
                linkGooglePlayStore {
                  target
                  title
                  url
                }
              }
              items {
                image {
                  altText
                  mediaItemUrl
                }
                link {
                  target
                  title
                  url
                }
                text
              }
              myVenue {
                text
                title
              }
              textWImage {
                image {
                  altText
                  mediaItemUrl
                }
                linkAppStore {
                  target
                  title
                  url
                }
                linkGooglePlayStore {
                  target
                  title
                  url
                }
                subtitle
                text
                title
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_SponsorsTab {
              fieldGroupName
              nameTab
              becomeASponsor {
                subtitle
                title
                backgroundImage {
                  altText
                  mediaItemUrl
                }
                button {
                  target
                  title
                  url
                }
              }
              logosSponsor: logos {
                logo {
                  altText
                  mediaItemUrl
                }
              }
            }
            ... on WPGraphQL_OurProject_Flexcontent_FlexibleContent_PastEventsTab {
              nameTab
              gallery {
                images {
                  image {
                    altText
                    mediaItemUrl
                  }
                }
                title
              }
              fieldGroupName
              grid {
                items {
                  year
                  image {
                    altText
                    mediaItemUrl
                  }
                  text
                  link {
                    target
                    title
                    url
                  }
                }
                title
              }
              logos {
                title
                images {
                  image {
                    altText
                    mediaItemUrl
                  }
                }
              }
              titleWText {
                text
                title
                filters {
                  year
                }
              }
            }
          }
        }
      }
    }
  }
`
