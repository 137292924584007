import React from "react"
import frame from '../../assets/images/frame-art-1.png';


const ProgramTeamTab = ({content, index}) => {
    const tabName = content.nameTab;
    const text = content.text;
    const titleWText = content.titleWText;
    const teams = content.teams;


    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
            <div className="content-text-area with_team">
                {
                    titleWText.title && <h2 className="text-center">{titleWText.title}</h2>
                }

                {
                    titleWText.text && <div className="text_bottom" dangerouslySetInnerHTML={{ __html: titleWText.text }}></div>
                }

                {
                    teams && 
                    <div className="teams_wrapper">
                        {
                            teams.map((item, index) => {
                                if(item) {
                                    return (
                                        <div className="team">
                                            {
                                                item.image &&
                                                <div className="img_area">
                                                    <img src={frame} alt="frame art 1" className="bg_frame" />
                                                    <img src={item.image.mediaItemUrl} alt={item.image.altText} />
                                                </div>
                                            }
                                            <div className="text_area">
                                                { item.name && <h3>{item.name}</h3>}
                                                { item.text && <p>{item.text}</p>}
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }

                    </div>
                }

                {
                    text && <p dangerouslySetInnerHTML={{ __html: text }}></p>
                }
            </div>
        </div>
    )
}

export default ProgramTeamTab
