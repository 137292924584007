import React from "react"


const FaqsTab = ({content, index}) => {
    const tabName = content.nameTab;
    const title = content.title;
    const leftFaqsNew = content.leftFaqsNew;
    const rightFaqs = content.rightFaqs;


    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
            <div className="content-text-area with_faq">
                {title && <h2 className="text-center">{title}</h2> }
                
                {
                    (leftFaqsNew || rightFaqs) && 
                    <div className="faq">
                        {
                            leftFaqsNew && 
                            <div className="left_area">
                                {
                                    leftFaqsNew.map((item, index) => {
                                        if(item) {
                                            return (
                                            <div className="item_faq">
                                                { item.questionLeft && <h5>{item.questionLeft}</h5> }
                                                { item.answerLeft && <div className="text_area" dangerouslySetInnerHTML={{ __html: item.answerLeft }}></div> }
                                            </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }

                        {
                            rightFaqs && 
                            <div className="right_area">
                                                                {
                                rightFaqs.map((item, index) => {
                                        if(item) {
                                            return (
                                            <div className="item_faq">
                                                { item.question && <h5>{item.question}</h5> }
                                                { item.answer && <div className="text_area" dangerouslySetInnerHTML={{ __html: item.answer }}></div> }
                                            </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }

                    </div>
                }
            </div>
        </div>
    )
}

export default FaqsTab
