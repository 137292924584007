import React from "react"
import frame from '../../assets/images/frame-art-1.png';
import ig from '../../assets/images/svg/art-ig.svg';
import web from '../../assets/images/svg/art-web.svg';

const OurArtistsTab = ({content, index}) => {
    const tabName = content.nameTab;
    const items = content.items;
    const titleWText = content.titleWText;


    const slugify = text =>
    text
        .toString()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    return (
        <div className={`tab-pane fade ${index} ${index == 2 ? 'show active' : ''}`} id={"nav-" + slugify(tabName)} role="tabpanel" aria-labelledby={`nav-${slugify(tabName)}-tab`} tabIndex={index}>
            {
                (titleWText.title || titleWText.text) && 
                <div className="content-text-area space_more">
                    { titleWText.title && <h2 className="text-center">{titleWText.title}</h2> }
                    <div className="text_area" dangerouslySetInnerHTML={{ __html: titleWText.text }}></div>
                </div>
            }
            {
                items &&
                <div className="people_wrapper">
                    {
                        items.map((item, index) => {
                            if(item) {
                                return (
                                    <div className="people_item">
                                        <div className="img_area">
                                            {
                                                item.image &&
                                                <div className="img_wrapper">
                                                    <img src={frame} alt="frame art 1" className="bg_frame" />
                                                    <img src={item.image.mediaItemUrl} alt={item.image.altText} />
                                                </div>
                                            }
                                            <div className="social">
                                                { item.instagramLink && <a href={item.instagramLink} target="_blank"><img src={ig} alt="ig" /></a> }
                                                { item.websiteLink && <a href={item.websiteLink} target="_blank"><img src={web} alt="website" /></a> }
                                                
                                            </div>
                                        </div>
                                        <div className="text_area">
                                            { item.title && <h3>{item.title}</h3> }
                                            <div className="text_area" dangerouslySetInnerHTML={{ __html: item.text }}></div>
                                            
                                        </div>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            }
        </div>
    )
}

export default OurArtistsTab
