import React from "react"

const BannerAboutUs = ({content}) => {
    const pretitle = content.pretitle;
    const backgroundImage = content.backgroundImage;
    const text = content.text;
    const title = content.title;
    const images = content.images;

    if(pretitle || backgroundImage || text || title || images) {
        return (
            <section className="banner-affordable switch_image about-us-banner">
                <div className="background-image">
                    { backgroundImage && <img src={backgroundImage.mediaItemUrl} alt={backgroundImage.altText} className="img-fluid" /> }
                    
                </div>
                <div className="top-area">
                    <div className="image-left">
                        { (images && images.length > 1) &&                    
                            <div className="slider-image orange-dots">
                                {
                                    images.map((item, index) => {
                                        if(item) {
                                            return (
                                            <div className="slider-image-box">
                                                <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" />
                                            </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        }
    
                        {
                            (images && images.length == 1) && 
                                images.map((item, index) => {
                                    if(item) {
                                        return (
                                        <img src={item.image.mediaItemUrl} alt={item.image.altText} className="img-fluid" />
                                        )
                                    }
                                })
                        }
                    </div>
                    <div className="text-box-right">
                        { pretitle && <h3>{pretitle}</h3> }
                        { title && <h1 dangerouslySetInnerHTML={{ __html: title }}></h1> }                    
                    </div>
                </div>
                <div className="text-area">
                    { text && <p dangerouslySetInnerHTML={{ __html: text }}></p> }
                    
                </div>
            </section>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default BannerAboutUs
