import React from "react"

const Logos = ({content}) => {
    const title = content.title;
    const logos = content.logosLogo;

    if(title || logos) {
        return (
        <div className="all_logo_images with_title">
            <div className="content_area">
                <div className="title_area">
                    {
                        title && <h2>{title}</h2>
                    }
                    
                </div>
                {
                    logos && 
                    <div className="img_wrapper">
                        {
                            logos.map((item, index) => {
                                if(item && item.logo && item.logo.mediaItemUrl) {
                                    return (
                                    <div className="single_image">
                                        <img src={item.logo.mediaItemUrl} alt={item.logo.altText} />
                                    </div>
                                    )
                                }
                            })
                        }
                    </div>
                }
            </div>
        </div>
        )
    } else {
        return(
            <div></div>
        )
    }
}

export default Logos
